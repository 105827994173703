import React, { Component } from "react"
import { Container, Badge, Row, Col } from "reactstrap"
import "./project.scss"

import ProjCard from "./projCard"

let mockCategories = ["Alle", "Gartenbau", "Gartenpflege", "Metallbau", "Häcksel", "Diverses"]

const CATEGORIES = ["Alle", "Gartenbau", "Gartenpflege", "Diverses", "Konstruktion", "Interior"];
class Project extends Component {
  state = {
    category: null
  }

  componentDidMount() {
    const { projects } = this.props
    const currProj = window.location.href.split('#')[1].replace('%20', ' ')

    const projCategoriesQuery = projects.filter(node => {
      return node.node.title === currProj
    })
    
    const projCategories = (projCategoriesQuery.length && projCategoriesQuery[0].node.category) || mockCategories

    this.setState({
      category: projCategories?.[0] || "Alle",
    })
  }

  render() {
    const { projects, title } = this.props

    return (
      <div className="project section-lg" id="project">
        <Container>
          <h2 className="header-title text-center font-weight-bold text-uppercase">
            {title}
          </h2>
          <section className="gallery-cat text-center">
            {CATEGORIES.map((projCategory, index) => {
              return (
                <Badge
                  key={index}
                  color={projCategory === this.state.category ? "secondary" : "light"}
                  onClick={() => this.setState({ category: projCategory })}
                >
                  {projCategory}
                </Badge>
              )
            })}
          </section>
          <section className="gallery">
            <Row>
              {this.state.category === "Alle"
                ? projects.map(project => {
                  return (
                    <Col lg="4" md="6" sm="12">
                      <ProjCard proj={project.node} />
                    </Col>
                  )
                })
                : projects
                  .filter(project => project.node.category.includes(this.state.category))
                  .map(project => {
                    return (
                      <Col lg="4" md="6" sm="12">
                        <ProjCard proj={project.node} />
                      </Col>
                    )
                  })}
            </Row>
          </section>
        </Container>
      </div>
    )
  }
}

export default Project
